import { Component, OnInit, HostBinding } from '@angular/core';
import { NgForm } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router, ActivatedRoute } from '@angular/router';
import { Login } from './../../models';
import { AuthService } from './../../services';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @HostBinding('class') cAppClass = 'c-app flex-row align-items-center c-legacy-theme';
  isLoading = false;
  isLoaded = false;
  shakeClass = '';
  loginDto: Login = { userName: '', password: '', token: '', isAuthenticated: false };
  token = '';
  loginForm: NgForm;
  imgsrc = "";
  bgs: string[]=[
    "assets/img/hall-roof-2560454_640.jpg",
    "assets/img/windmills-1149604_640.jpg",
    "assets/img/industry-4855767_640.jpg",
    "assets/img/solar-energy-2157212_640.jpg",
    "assets/img/wind-turbine-2218457_640.jpg"
  ];
  constructor(private toastr: ToastrService, private api: AuthService, private jwtHelper: JwtHelperService, private router: Router, private route: ActivatedRoute) {

  }
  ngOnInit(): void {
    localStorage.removeItem('access_token');
    let i = _.random(0, 4);
    this.imgsrc = this.bgs[i];
  }
  submit(): void {
    this.api.authorize(this.loginDto).subscribe((res) => {
      let payload = this.jwtHelper.decodeToken(res.token);

      if (res.isAuthenticated) {
        this.shakeClass = '';
        localStorage.setItem('access_token', res.token);
        this.router.navigate(['/dashboard']);

      }
      else {
        this.toastr.error('Login Failed', payload.message);
        this.shakeClass = 'animated shake';
        setTimeout(() => { this.shakeClass = ''; }, 2000);
      }
    }
    );
  }
}
