import { ProjectType, ProjectStatus } from './';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';

export interface CustomerSimple {
  id: number;
  name: string;
}
export interface DivisionSimple {
  customerId: number;
  id: number;
  name: string;
}
export interface SiteSimple {
  id: number;
  name: string;
  divisionId: number;
}
export interface StandardReport {
  customerId: number;
  customerName: string;
  years: number;
  defaultProjects: StandardReportGroup;
  divisionProjects: StandardReportGroupType;
  siteProjects: StandardReportGroupType;
  projectTypeProjects: StandardReportGroupType;
  statusProjects: StandardReportGroupType;
  barChartLabels: Label[],
}
export interface StandardReportGroupType {

  standardReportType: StandardReportType;
  projectSpend: number;
  savings: number;
  mtCo2e: number;
  mtCo2ePrice: number;
  projects: StandardReportGroup[];

}
export interface StandardReportGroup {
  groupBy: string;
  projectSpend: number;
  savings: number;
  netAnnualSavingsOverLife: number;
  mtCo2e: number;
  mtCo2ePrice: number;
  projects: StandardReportProject[];
  nonEconProjects: StandardReportProject[];
  cumulativeTotals: StandardReportProjectCumulativeTotal[];
  cumulativeMTCO2eChartOption: ChartOptions;
  cumulativeMTCO2eChartData: ChartDataSets[];
  defaultCumulativeSavingsChartOption: ChartOptions;
  defaultCumulativeSavingsChartData: ChartDataSets[];
  divisionProjects: DivisionStandardReportProject[];
}
export interface DivisionStandardReportProject {
  divisionId?: number;
  divisionName?: string;
  projectSpend?: number;
  mtCo2e?: number;
  netAnnualSavingsOverLife?: number;
  projects?: StandardReportProject[];
  nonEconProjects?: StandardReportProject[];
}
export interface StandardReportProject {
  divisionName: string;
  siteName: string;
  division: DivisionSimple;
  site: SiteSimple;
  projectType: ProjectType;
  projectStatus: ProjectStatus;
  startDate: Date;
  projectSpend: number;
  spb: number;
  savings: number;
  mtCo2e: number;
  mtCo2ePrice: number;
  pricePerRevMTCO2e: number;
  lifeOfProject: number;
}
export interface StandardReportProjectCumulativeTotal {
  year: number;
  totals: StandardReportProjectTotal[];
  target?: number;
}export interface StandardReportProjectTotal {
  projectStatus: ProjectStatus;
  mtCo2e: number;
  savings: number;
}
export enum StandardReportType {
  All = "All",
  Default = "Default",
  Division = "Division",
  Site = "Site",
  Project = "Project",
  Status = "Status"
}
