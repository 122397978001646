import * as _ from 'lodash';
import * as moment from "moment";
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class NgTokenRefresher implements HttpInterceptor {

  constructor(private router: Router, private toastr: ToastrService, private jwtHelper: JwtHelperService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpHeaderResponse | HttpResponse<any>> {
    return next.handle(req).pipe(
      map(resp => {
        if (resp instanceof HttpResponse) {
          let exp = moment(this.jwtHelper.getTokenExpirationDate());
          let refreshTokenHeader = resp.headers.get('Authorization');

          if (exp.isValid() && refreshTokenHeader && refreshTokenHeader.split(' ').length === 2) {

            const refreshToken = resp.headers.get('Authorization').split(' ')[1];
            localStorage.removeItem('access_token');
            localStorage.setItem('access_token', refreshToken);
          }
          return resp.clone();
        }
      })
    );
  }
}
