import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtPayload } from './../models';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private jwtHelper: JwtHelperService, private toastr: ToastrService) { }

    canActivate() {
  
      if (localStorage.getItem('access_token')) {
        let payload = this.jwtHelper.decodeToken<JwtPayload>();
        let isTokenExpired = this.jwtHelper.isTokenExpired();
        let isAuthenticated = payload.isAuthenticated;
  
        if (!isAuthenticated) {
          this.toastr.error('Error', `You are not authenticated`);
          this.router.navigate(['/login']);
          return false;
        }
        if (isTokenExpired) {
          this.toastr.error('Error', `This token is expired`);
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      }

      this.toastr.error('Error', `ERROR!`);
      this.router.navigate(['/login']);
      return false;
    }
  
    canActivateChild() {
      return this.canActivate();
    }
}

@Injectable()
export class AuthGuardAdmin implements CanActivate {
  constructor(private router: Router, private jwtHelper: JwtHelperService, private toastr: ToastrService) { }

  canActivate() {
    
    if (localStorage.getItem('access_token')) {
      const payload = this.jwtHelper.decodeToken<JwtPayload>();
      
      const isTokenExpired = this.jwtHelper.isTokenExpired();
      const isAuthenticated = payload.isAuthenticated;
      
      const isAdmin = _.findIndex(payload.Roles, r => { return r === "Admin" });
      if (isAdmin >= 0) {
        return true;
      }
      this.router.navigate(['/dashboard']);
      this.toastr.error('Error', `You are not authorize`);
      return false;
    }

    this.toastr.error('Error', `ERROR!`);
    this.router.navigate(['/dashboard']);
    return false;
  }

  canActivateChild() {
    return this.canActivate();
  }
}
