import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//Gueard
import { AuthGuard, AuthGuardAdmin } from './_guards';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';



export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path:"login",
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'customers',
        loadChildren: () => import('./views/customer/customer.module').then(m => m.CustomerModule)
      },
      {
        path: 'divisions',
        loadChildren: () => import('./views/division/division.module').then(m => m.DivisionModule)
      },
      {
        path: 'sites',
        loadChildren: () => import('./views/site/site.module').then(m => m.SiteModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('./views/project/project.module').then(m => m.ProjectModule)
      },
      {
        path: 'co2',
        loadChildren: () => import('./views/co2/co2.module').then(m => m.Co2Module)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/report/report.module').then(m => m.ReportModule)
      },
      {
        canActivate: [AuthGuardAdmin],
        path: 'admin/users',
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/user-profile/user-profile.module').then(m => m.UserProfileModule)
      },
      {
        path: 'changepassword',
        loadChildren: () => import('./views/change-password/change-password.module').then(m => m.ChangePasswordModule)
      },
      {
        path: 'co2Targets',
        loadChildren: () => import('./views/co2-targets/co2-targets.module').then(m => m.Co2TargetsModule)
      },
      {
        path: 'documents',
        loadChildren: () => import('./views/customer-document/customer-document.module').then(m => m.CustomerDocumentModule)
      },
      {
        path: 'monitor',
        loadChildren: () => import('./views/monitor/monitor.module').then(m => m.MonitorModule)
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
