import { Directive, Renderer2, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { KlFormatPipe } from './../pipes';

@Directive({
  selector: '[projectInputFormatter]'
})
export class ProjectInputFormatterDirective {

  constructor(private renderer: Renderer2, private el: ElementRef, private klFormat: KlFormatPipe) { }
  @Input("decimalPoints") decimalPoints: string;
  @HostListener('focus') onFocus() {
    let value = this.el.nativeElement.value;
    var input = value.replace(/[\,\$\%\(\)]+/g, "");
    let isNaN = Number.isNaN(Number.parseFloat(input));
    if (!isNaN) {
      this.renderer.setProperty(
        this.el.nativeElement,
        'value',
        (Number.parseFloat(input))
      );
      this.el.nativeElement.select();
    }    
  }
  @HostListener('blur') onBlur() {
    let value = this.el.nativeElement.value;
    
    let input = value.replace(/[\,\$\%\(\)]+/g, "");
    let dp = this.decimalPoints || "0";
    let isNaN = Number.isNaN(Number.parseFloat(input));
    if (isNaN) {
      this.renderer.setProperty(
        this.el.nativeElement,
        'value',
        ''
      );
    }
    else {
      this.renderer.setProperty(
        this.el.nativeElement,
        'value',
        this.klFormat.transform((Number.parseFloat(input)), { pipe: 'number', 'parameters': [`1.${dp}-${dp}`] })
      );
    }
    
  }

  ngOnInit(): void {
    let value = this.el.nativeElement.value;
    let input = value.replace(/[\,\$\%\(\)]+/g, "");
    let dp = this.decimalPoints || "0";
    let isNaN= Number.isNaN(Number.parseFloat(input));
    this.renderer.setProperty(
      this.el.nativeElement,
      'value',
      this.klFormat.transform(
        (isNaN || input === '' ? 0 : Number.parseFloat(input)),
        { pipe: 'number', 'parameters': [`1.${dp}-${dp}`] }
      )
    );
  }
}
