import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectType'
})
export class projectTypePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (value === "PowerFactor") {
      return "Power Factor";
    }
    if (value === "HotWaterSanitation") {
      return "Hot Water Sanitation";
    }
    if (value === "HotWaterExpansion") {
      return "Hot Water Expansion";
    }
    if (value === "CompressedAir") {
      return "Compressed Air";
    }
    return value;
  }

}

@Pipe({
  name: 'projectStatus'
})
export class projectStatusPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (value === "InConstruction") {
      return "In Construction";
    }
    return value;
  }

}

@Pipe({
  name: 'projectStatusBadge'
})
export class projectStatusBadgePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (value === "InConstruction") {
      return "warning";
    }
    if (value === "Proposed") {
      return "dark";
    }
    if (value === "Completed") {
      return "success";
    }
    if (value === "Dead") {
      return "danger";
    }
    return 'info';
  }

}
