import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataFilterPipe, YesNoPipe, FilterByPipe, MaxTextPipe, KlFormatPipe, projectTypePipe, projectStatusPipe, MinusSignToParens, projectStatusBadgePipe } from './pipes';
import { ProjectInputFormatterDirective, ProjectIconDirective, BgScoreDirective } from './directives';
@NgModule({
  declarations: [DataFilterPipe, YesNoPipe, FilterByPipe, MaxTextPipe, KlFormatPipe, ProjectInputFormatterDirective, ProjectIconDirective, BgScoreDirective, projectTypePipe, projectStatusPipe, MinusSignToParens, projectStatusBadgePipe],
  exports: [DataFilterPipe, YesNoPipe, FilterByPipe, MaxTextPipe, KlFormatPipe, ProjectInputFormatterDirective, ProjectIconDirective, BgScoreDirective, projectTypePipe, projectStatusPipe, MinusSignToParens, projectStatusBadgePipe],
  imports: [
    CommonModule
  ],
  providers: [DataFilterPipe, YesNoPipe, FilterByPipe, MaxTextPipe, KlFormatPipe, ProjectInputFormatterDirective, ProjectIconDirective, BgScoreDirective, projectTypePipe, projectStatusPipe, MinusSignToParens, projectStatusBadgePipe]
})
export class KnowLoadSharedModule { }
