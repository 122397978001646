import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectType } from './../models';

@Injectable({
  providedIn: 'root'
})


export class UtilService {

  constructor() { }

  parseNumber(v: string): number {
    let itemValue = new String(v || "0");
    let itemNum = itemValue.replace(/[\,\$\%\(\)]+/g, "");
    return Number.isNaN(Number.parseFloat(itemNum)) ? 0 : Number.parseFloat(itemNum);
  }
  sum(items: string[], form: FormGroup): number {
    let value = 0;
    _.each(items, (i) => {
      value += this.parseInputNumber(i, form);
    });
    return value;

  }
  parseInputNumber(item: string, form: FormGroup): number {
    let itemValue = new String(form.controls[item].value || "0");
    let itemNum = itemValue.replace(/[\,\$\%\(\)]+/g, "");
    return Number.isNaN(Number.parseFloat(itemNum)) ? 0 : Number.parseFloat(itemNum);
  }
  cO2EmmissionsReducedLbs(projectType: ProjectType,
    electricSavingsVolume: number,
    importElectricalExpenseVolume: number,
    otherExpenseVolume: number,
    importElectricalExpenseNewTariffVolume: number,
    utilityKWhSavings: number): number {
    let value = 0;

    if (projectType === ProjectType.Generation) {
      value = electricSavingsVolume - importElectricalExpenseVolume;
    }
    else if (projectType === ProjectType.Refrigeration || projectType === ProjectType.Controls) {
      value = electricSavingsVolume - otherExpenseVolume;
    }
    else if (projectType === ProjectType.Lighting) {
      value = electricSavingsVolume;
    }
    else if (projectType === ProjectType.Solar) {
      value = electricSavingsVolume - importElectricalExpenseNewTariffVolume;
    }
    else if (projectType === ProjectType.PowerFactor) {
      value = utilityKWhSavings;
    }
    else if (projectType === ProjectType.HotWaterSanitation) {
      value = electricSavingsVolume;
    }
    else if (projectType === ProjectType.Condensate) {
      value = electricSavingsVolume - otherExpenseVolume;
    }
    else if (projectType === ProjectType.Economizer) {
      value = electricSavingsVolume - otherExpenseVolume;
    }
    else if (projectType === ProjectType.Boiler) {
      value = electricSavingsVolume ;
    }
    else if (projectType === ProjectType.DAF) {
      value = electricSavingsVolume ;
    }
    else if (projectType === ProjectType.Blower) {
      value = electricSavingsVolume ;
    }
    else if (projectType === ProjectType.CompressedAir) {
      value = electricSavingsVolume ;
    }
    else if (projectType === ProjectType.HotWaterExpansion) {
      value = electricSavingsVolume ;
    }
    else { value = 0;}
    
    return value;
  }
}
