//Project.ts
import { Division, Customer, Site } from './';

export enum ProjectType {
  Solar = "Solar",
  Lighting = "Lighting",
  Generation = "Generation",
  Refrigeration = "Refrigeration",
  PowerFactor = "PowerFactor",
  HotWaterSanitation = "HotWaterSanitation",
  Condensate = "Condensate",
  Economizer = "Economizer",
  Controls = "Controls",
  Boiler = "Boiler",
  DAF = "DAF",
  Blower = "Blower",
  CompressedAir = "CompressedAir",
  HotWaterExpansion = "HotWaterExpansion"

}
export enum ProjectStatus {
  Proposed = "Proposed",
  InConstruction = "InConstruction",
  Completed = "Completed",
  Dead = "Dead"
}
export enum ProjectDisplay {
  Summary = "Summary",
  Extended = "Extended",
  Diff = "Diff"
}
export interface Project {
  id: number;
  isActive: boolean;
  parentId: number;
  entryDate: Date;
  startDate: Date;
  description: string;
  createdBy: string;
  siteId: number;
  projectType: ProjectType;
  projectStatus: ProjectStatus;
  subcontractorLabor: number;
  subcontractorEquipment: number;
  auditDesign: number;
  aesLabor: number;
  materialPurchase: number;
  materialFreight: number;
  subcontractorMaterial: number;
  miscellaneous: number;
  genset: number;
  catalyticConverterforGenset: number;
  switchgearandTransformers: number;
  heatRecoveryPackage: number;
  travel: number;
  recyclingDisposal: number;
  contingency: number;
  powerMeter: number;
  otherMiscellaneous: number;
  investmentTaxCreditPtc: number;
  depreciationFedPtc: number;
  depreciationStatePtc: number;
  rebatePtc: number;
  investmentTaxCredit: number;
  depreciationFed: number;
  depreciationState: number;
  rebate: number;
  salesTax: number;
  previousCostBilledExistingTariffVolume: number;
  remainingCostVolume: number;
  previousCostBilledExistingTariffRate: number;
  remainingCostTariffRate: number;
  avoidedRoofExpense: number;
  otherSavings: number;
  otherIncentive: number;
  otherSavingsType?: string;
  water: number;
  naturalGas: number;
  maintenance: number;

  grease: number;
  labor: number;
  miscelanneousSavings: number;
  avoidedCosts: number;

  annualMaintenanceSavings: number;
  annualHVACSavings: number;
  previousCostBilledExistingNgVolume: number;
  remainingCostNgVolume: number;
  previousCostBilledExistingNgRate: number;
  remainingCostNgRate: number;
  measure1: number;
  measure2: number;
  powerFactorCorrectionSavings: number;
  annualkWhUsage: number;
  originalPowerFactor: number;
  correctedPowerFactor: number;
  importElectricalExpenseNewTariffVolume: number;
  importElectricalExpenseNewTariffRate: number;
  gridStandByCosts: number;
  annualEmissionsTesting: number;
  oMExpense: number;
  importElectricalExpenseVolume: number;
  importElectricalExpenseRate: number;
  otherNGExpenseDT: number;
  otherNGExpenseHR: number;
  otherNGExpenseMP: number;
  gensetNGExpenseDT: number;
  gensetNGExpenseHR: number;
  gensetNGExpenseMP: number;
  otherExpenseVolume: number;
  otherExpenseRate: number;
  federalTaxRate: number;
  stateTaxRate: number;
  federalTaxValue: number;
  stateTaxValue: number;
  co2RegionId: number;
  year: number;
  cO2EmmissionsRate: number;
  lifeofProject: number;
}
export interface ProjectExtended extends Project {
  totalLabor: number;
  materialPurchaseGeneration: number;
  totalMaterial: number;
  totalOther: number;
  totalProjectSpend: number;
  totalRebateIncentive: number;
  salesTaxAmount: number;
  totalCost: number;
  previousCostBilledExistingTariffValue: number;
  remainingCostValue: number;
  electricSavingsVolume: number;
  electricSavingsValue: number;
  totalOtherSavings: number;
  previousCostBilledExistingNgValue: number;
  remainingCostNgValue: number;
  electricSavingsNgVolume: number;
  electricSavingsNgValue: number;
  totalMeasureSavings: number;
  originalPowerValue: number;
  correctedPowerValue: number;
  utilityKWhSavings: number;
  totalAnnualSavings: number;
  importElectricalExpenseNewTariffValue: number;
  importElectricalExpenseValue: number;
  otherNGExpenseValue: number;
  gensetNGExpenseValue: number;
  otherExpenseValue: number;
  totalAdditionalCosts: number;
  netAnnualSavings: number;
  totalTaxValue: number;
  savingsincludingTaxImpact: number;
  yearOneCashFlow: number;
  spb: number;
  cO2EmmissionsReducedLbs: number;
  cO2EmmissionsReducedValue: number;
  cO2EmmissionsReducedMt: number;
  mtCO2eReducedNgVolume: number;
  mtCO2eReducedNgRate: number;
  mtCO2eReducedNgValue: number;
  combinedCO2EmmissionsReducedMt: number;
  cO2EmmissionsReducedMtForLifeofProject: number;
  pricePerMtCo2e: number;
  pricePerRevMTCO2eValue: number;
  pricePerRevMTCO2e: number;
  netAnnualSavingsOverLife: number;
}
export interface Format {
  excel: string;
  angular: AngularFormat;
}
export interface AngularFormat {
  pipe: string;
  parameters: string[];
}
export interface ProjectSummary {
  projectDisplay?: ProjectDisplay;
  projectType?: ProjectType;
  projectStatus?: ProjectStatus;
  projectStatusClone?: ProjectStatus;
  pojectViewDisplaySummary?: PojectViewDisplay;
  pojectViewDisplayExtended?: PojectViewDisplay;
  pojectViewDisplayDiff?: PojectViewDisplay;
  startDate?: Date;
  projectId: number;
  startMonth?: number;
  startYear?: number;
}
export interface PojectViewDisplay {
  cards: PojectViewDisplayCard[];
}
export interface PojectViewDisplayCard {
  cssClass: string;
  cardHeader: string;
  borderColor: string;
  components: PojectViewDisplayLabel[];
  editLabel: string;
  editId: number;
  proposedorCurrent: string;
}
export interface PojectViewDisplayLabel {
  label: string;
  cssClass: string;
  items: PojectViewDisplayItem[];
}
export interface PojectViewDisplayItem {
  isBlank: boolean;
  isStringValue: boolean;
  value: number;
  stringValue: string;
  cssClass: string;
  textCss?: string;
  format: Format;
}

export interface ProjectView {
  customer: Customer;
  division: Division;
  site: Site;
  projects: ProjectSummary[];
}
export interface ProjectStatusUpdateDto {
  id?: number;
  projectStatus?: ProjectStatus;
  startMonth?: number;
  startYear?: number;
  startDate?: Date;
}
