import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Login } from './../models';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private authUrl = '/auth.json';  // URL to web api

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) { }

  authorize(loginDto: Login): Observable<Login> {
    return this.http.post<Login>(this.authUrl, loginDto, httpOptions);
  }

}
