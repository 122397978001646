import * as _ from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {

  transform(array: any[], entity: string, query: string): any {
    if (query && entity) {
      return _.filter(array, row => row[entity].toUpperCase().indexOf(query.toUpperCase()) > -1);
    }
    return array;
  }

}


