import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxText'
})
export class MaxTextPipe implements PipeTransform {

  transform(value: string, len :number): string {
    let r = value.length > len
      ? value.slice(0, len) + "..."
      : value;
    return r;

  }

}
