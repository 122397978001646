import { Component, OnInit, HostBinding } from '@angular/core';
import { JwtPayload } from './../../models';
import { JwtHelperService } from '@auth0/angular-jwt';
import { INavData } from '@coreui/angular';
import { menuNavItems } from './_nav';
import * as _ from 'lodash';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent {
  navItems: INavData[] = [];
  payLoad: JwtPayload;
  adminItems: INavData[]=[ 
    {
      title: true,
      name: 'Administration'
    },
    {
      name: 'Users',
      url: '/admin/users',
      icon: 'cil-people'
    }];

  @HostBinding('class.c-app') cAppClass = true;

  perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor(private jwtHelper: JwtHelperService) {
    this.navItems = [];
    this.navItems = menuNavItems;
  }

  ngOnInit(): void {
    this.payLoad = this.jwtHelper.decodeToken<JwtPayload>();
    const isAdmin = _.findIndex(this.payLoad.Roles, a => { return a === "Admin"; }) >= 0;
    if (isAdmin) {
      this.navItems = _.union(this.navItems, this.adminItems);
    }
  }
}
