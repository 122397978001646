<li class="c-header-nav-item dropdown" dropdown placement="bottom">
  <a [routerLink] class="c-header-nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
    <c-icon name="cilUser" size="lg"></c-icon>
  </a>
  <div class="dropdown-menu dropdown-menu-right pt-0" *dropdownMenu aria-labelledby="account-dropdown">
    <div class="dropdown-header text-center bg-light">
      <strong>Account</strong>
    </div>
    <a [routerLink]="['/profile/']" class="dropdown-item">
      <c-icon name="cil-user" class="mfe-2"></c-icon>
      Profile
    </a>
    <a [routerLink]="['/changepassword/']" class="dropdown-item">
      <c-icon name="cil-settings" class="mfe-2"></c-icon>
      Change Password
    </a>
    <a [routerLink] class="dropdown-item" (click)="logout()">
      <c-icon name="cil-lock-locked" class="mfe-2"></c-icon>
      Logout
    </a>
  </div>
</li>
