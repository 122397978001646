import { Directive, Renderer2, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[projectIcon]'
})
export class ProjectIconDirective {

  constructor(private renderer: Renderer2, private el: ElementRef) { }
  @Input('projectType') projectType: string;
  ngOnInit(): void {
    if (this.projectType === "Solar") {
      this.renderer.addClass(this.el.nativeElement, 'cis-sun')
    }
    if (this.projectType === "Lighting") {
      this.renderer.addClass(this.el.nativeElement, 'cis-lightbulb')
    }
    if (this.projectType === "Generation") {
      this.renderer.addClass(this.el.nativeElement, 'cis-energy-circle')
    }
    if (this.projectType === "Refrigeration") {
      this.renderer.addClass(this.el.nativeElement, 'cis-snowflake')
    }
    if (this.projectType === "PowerFactor") {
      this.renderer.addClass(this.el.nativeElement, 'cis-bolt')
    }
    
  }
}
