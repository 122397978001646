import { INavData } from '@coreui/angular';

export const menuNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-speedometer'
  },
  {
    name: 'Customers',
    url: '/customers',
    icon: 'cil-factory'
  },
  {
    name: 'Documents',
    url: '/documents',
    icon: 'cil-document'
  },
  {
    name: 'Divisions',
    url: '/divisions',
    icon: 'cil-building-business'
  },
  {
    name: 'CO2 Targets',
    url: '/co2Targets',
    icon: 'cil-cloud-slash'
  },
  {
    name: 'Sites',
    url: '/sites',
    icon: 'cil-building'
  },
  {
    name: 'Projects',
    url: '/projects',
    icon: 'cil-calculator'
  },
  {
    name: 'CO2',
    url: '/co2',
    icon: 'cil-cloud'
  },
  {
    name: 'Monitoring',
    url: '/monitor',
    icon: 'cil-chart-multiline'
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: 'cil-chart',
    children: [
      {
        name: 'Standard',
        url: '/reports/standard',
        icon: 'cil-calculator'
      },
      {
        name: 'GHG Score',
        url: '/reports/ghgScore',
        icon: 'cil-list-numbered'
      }
    ]
  }
];
