import { Directive, Renderer2, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[bgScore]'
})
export class BgScoreDirective {

  constructor(private renderer: Renderer2, private el: ElementRef) { }
  @Input('score') score: number;
  ngOnInit(): void {

    if (this.score <= 5) {
      this.renderer.addClass(this.el.nativeElement, 'score-5');
    }
    else if (this.score > 5 && this.score <= 9) {
      this.renderer.addClass(this.el.nativeElement, 'score-4');
    }
    else if (this.score > 9 && this.score <= 13) {
      this.renderer.addClass(this.el.nativeElement, 'score-3');
    }
    else if (this.score > 13 && this.score <= 16) {
      this.renderer.addClass(this.el.nativeElement, 'score-2');
    }
    else if (this.score > 16 && this.score <= 20) {
      this.renderer.addClass(this.el.nativeElement, 'score-1');
    }
  }
}
