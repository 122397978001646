<c-container>
  <c-row class="justify-content-center">
    <c-col md="8">
      <c-card-group>
        <c-card class="p-4">
          <c-card-body>
            <form novalidate #loginForm="ngForm">
              <h1>Login</h1>

              <p class="text-muted">Sign In to your account</p>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <c-icon name="cil-user"></c-icon>
                  </span>
                </div>
                <input cInput
                       placeholder="Username@email.com"
                       autocomplete="username"
                       name="username" [(ngModel)]="loginDto.userName" required />
              </div>
              <div class="input-group mb-4">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <c-icon name="cil-lock-locked"></c-icon>
                  </span>
                </div>
                <input cInput
                       type="password"
                       placeholder="Password"
                       autocomplete="current-password"
                       name="password"
                       required
                       [(ngModel)]="loginDto.password" />
              </div>
              <c-row>
                <c-col col="6">
                  <button cButton color="primary" class="px-4" (click)="submit()" [disabled]="!loginForm.form.valid">Login</button>
                </c-col>
                <c-col col="6" class="text-right">
                  <!-- <button cButton color="link" class="px-0">Forgot password?</button> -->
                </c-col>
              </c-row>
            </form>
          </c-card-body>
        </c-card>
        <c-card class="text-white" style="width: 100%;">
          <c-card-body class="text-center login-con">
            <div class="login-innercon my-5">
              <img src="assets/ally/1x/Ally_Logo.png" class="mb-3" />
              <h2>Know-Carbon</h2>
              <p>Capture, Analyze and report energy efficiency &amp; carbon reduction</p>
            </div>
            <img class="login-img" [src]="imgsrc" />
          </c-card-body>
        </c-card>
      </c-card-group>
    </c-col>
  </c-row>
</c-container>
