<ng-container>
  <c-toggler class="d-md-none"
             cSidebarToggle="sidebar"
             inHeader="true"></c-toggler>
  <c-toggler class="d-sm-down-none mfs-3"
             cSidebarToggle="sidebar"
             id="sidebar-toggler"
             inHeader="true"></c-toggler>
  
  <!-- header links-->
  
  <c-toggler title="Toggle Light/Dark Mode" class="ml-auto" id="theme-toggler">
    <button [class]="'c-header-toggler'" type="button" (click)="toggleTheme()">
      <c-icon name="cilMoon" size="lg" class="c-d-dark-none"></c-icon>
      <c-icon name="cilSun" size="lg" class="c-d-default-none"></c-icon>
    </button>
  </c-toggler>
  <ul class="c-header-nav">
    <app-default-header-dropdown-account></app-default-header-dropdown-account>
  </ul>

  <c-subheader class="justify-content-between px-3">
    <!--breadcrumbs-->
    <c-breadcrumb class="border-0 m-0 px-0 px-md-3 c-subheader-nav"
                  cBreadcrumbRouter></c-breadcrumb>
  </c-subheader>
 
</ng-container>
