import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NgHttpInterceptorService implements HttpInterceptor {

  constructor(private router: Router, private toastr: ToastrService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    return next.handle(req).pipe(
      map((event: any) => {

        return event;
      }),
      catchError((err: any, caught) => {

        if (err instanceof HttpErrorResponse) {
          if (err.status === 400) {
            this.toastr.error('ERROR 400', 'Bad Request.', { timeOut: 2000, progressBar: true, closeButton: true });

          }
          if (err.status === 404) {
            this.toastr.warning('ERROR 404', 'Page is not found  - 404', { timeOut: 2000, progressBar: true, closeButton: true });

          }
          if (err.status === 403) {
            this.toastr.error('Authentication Error', `Authentication: ${err.statusText}`, { timeOut: 2000, progressBar: true, closeButton: true });
            setTimeout(() => { this.router.navigate(['/login']); }, 2500);
          }
          if (err.status === 500) {
            this.toastr.error('Server Error', `Server Error: ${err.statusText}`, { timeOut: 2000, progressBar: true, closeButton: true });
          }
          return throwError(err);
        }
      })
    );
  }
}
