import * as saveAs from 'file-saver';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})


export class ApiService {
  private apiUrl = ''; // URL to web api

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) { }

  getFromUrl<TAny>(url: string): Observable<any> {
    const api = `${this.apiUrl}/${url}`;
    return this.http.get(api, httpOptions);
  }
  get<TAny>(model: string, id: number): Observable<TAny> {
    const api = `${this.apiUrl}/${model}/${id}.json`;

    return this.http.get<TAny>(api, httpOptions);
  }

  getAll<TAny>(model: string): Observable<TAny> {
    const api = `${this.apiUrl}/${model}.json`;

    return this.http.get<TAny>(api, httpOptions);

  }
  post<TAny>(model: string, entity: any): Observable<TAny> {
    const api = `${this.apiUrl}/${model}.json`;

    return this.http.post<TAny>(api, JSON.stringify(entity), httpOptions);
  }
  add<TAny>(model: string, entity: any): Observable<TAny> {
    const api = `${this.apiUrl}/${model}.json`;

    return this.http.post<TAny>(api, JSON.stringify(entity), httpOptions);
  }

  update<TAny>(model: string, entity: any): Observable<TAny> {
    const api = `${this.apiUrl}/${model}/${entity.id}.json`;

    return this.http.put<TAny>(api, JSON.stringify(entity), httpOptions);
  }

  updateAll<TAny>(model: string, entity: any): Observable<TAny> {
    const api = `${this.apiUrl}/${model}.json`;

    return this.http.put<TAny>(api, JSON.stringify(entity), httpOptions);
  }

  delete<TAny>(model: string, id: number): Observable<TAny> {
    const api = `${this.apiUrl}/${model}/${id}.json`;

    return this.http.delete<TAny>(api, httpOptions);
  }
  deleteFromUrl<TAny>(model: string): Observable<TAny> {
    const api = `${this.apiUrl}/${model}.json`;

    return this.http.delete<TAny>(api, httpOptions);
  }
  report<TAny>(report: string, dto: any): Observable<TAny> {
    const api = `${this.apiUrl}/${report}.json`;

    return this.http.post<TAny>(api, JSON.stringify(dto), httpOptions);
  }
  getReport<TAny>(report: string): Observable<TAny> {
    const api = `${this.apiUrl}/${report}.json`;

    return this.http.get<TAny>(api, httpOptions);
  }
  requestFile(model: string, dto: any): Observable<boolean> {
    const api = `${this.apiUrl}/${model}.json`;
    var subject = new Subject<boolean>();

    this.http.post(api, JSON.stringify(dto), { headers: { 'Content-Type': 'application/json' }, observe: 'response', responseType: 'blob' })
      .subscribe(response => {
        let contentDispositionHeader = response.headers.get('content-disposition');
        let extractedFileName = undefined;
        if (contentDispositionHeader) {
          extractedFileName = contentDispositionHeader.split(';')[1].trim().split('=')[1].replace(/"/g, '');
        }
        let fileName = extractedFileName || "KnowLoad Export";
        let blob = new Blob([response.body], { type: "octet/stream" });
        saveAs(blob, fileName);

        subject.next(true);
        subject.complete();
      },
        error => {
          subject.error('Error Saving File');
        });
    return subject;
  }
}
