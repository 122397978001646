<!--sidebar-->
<c-sidebar #sidebar="cSidebar" id="sidebar" unfoldable="unfoldable">
  <c-sidebar-brand
    [routerLink]="null"
    [brandFull]="{
      src: 'assets/ally/1x/Ally_Logo.png',
      width: 230,
      height: 56,
      alt: 'Ally Energy Solutions'
    }"
    [brandMinimized]="{
      src: 'assets/ally/1x/Ally_Icon.png',
      width: 49,
      height: 56,
      alt: 'Ally Logo'
    }"
  >
  </c-sidebar-brand>

  <c-sidebar-nav
    [navItems]="navItems"
    [perfectScrollbar]="perfectScrollbarConfig"
    [disabled]="sidebar?.minimized"
    dropdownMode="closeInactive"
  ></c-sidebar-nav>

  <c-sidebar-minimizer cSidebarMinimize="sidebar"></c-sidebar-minimizer>
</c-sidebar>

<!--main-->
<c-wrapper>
  <!--header-->
  <app-default-header [fixed]="true"></app-default-header>
  <!--app body-->
  <c-body>
    <main class="c-main">
      <c-container [fluid]="true">
        <router-outlet></router-outlet>
      </c-container>
    </main>
    <!--app footer-->
  </c-body>
  <c-footer>
    <div>
      <a href="https://ally-energy.com/" target="_blank">
        Ally Energy Solutions
      </a>
      <span> &copy; 2021 Isaias Izquierdo</span>
    </div>
  </c-footer>
</c-wrapper>
