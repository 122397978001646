import { Pipe, PipeTransform } from '@angular/core';
import { PercentPipe, DecimalPipe, CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'klFormat'
})

export class KlFormatPipe implements PipeTransform {

	pipeMap: {} = {
		'number': new DecimalPipe('en-US'),
     'percent': new PercentPipe('en-US'),
     'currency': new CurrencyPipe('en-US')
	};

    transform(value: any, angularPipe: any): any {
		if (!angularPipe || !angularPipe.pipe) return value;

		const pipe = this.pipeMap[angularPipe.pipe];
		return pipe.transform(value, ...angularPipe.parameters);
	}
}
